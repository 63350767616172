import { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
import "./About.css";
function AboutUs() {
  const { getTrans, lang } = useContext(TranslationContext);
  const { getData } = useContext(DataContext);

  const about = getData("about");

  return (
    <section
      dir={lang() === "en" ? "ltr" : "rtl"}
      className={(lang() === "en" ? "" : "ar ") + "about_section about_us py-5"}
    >
      <div className="container pt-5 ">
        <div className="detail-box ">
          <div className="heading_container mt-5">
            <h2 className="text-white" data-aos="fade-up">
              {getTrans("About Us")}
              <i className="ms-2 bi bi-circle-fill fs-4"></i>
            </h2>
          </div>
          <div className="text-white" data-aos="fade-up">
            {about.split("\n").map((line, i) => (
              <p key={i}>
                {line}
                <br />
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
