import React, { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
import right from "../../entreprise/right.svg";

import "./Home.css";

function Home() {
  const { getTrans, lang } = useContext(TranslationContext);
  const { getData, addSectionRef } = useContext(DataContext);

  return (
    <>
      <section
        dir={lang() === "en" ? "ltr" : "rtl"}
        ref={addSectionRef}
        id="home"
        className={(lang() === "en" ? "" : "ar ") + " slider_section "}
      >
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-12 px-xl-5 px-lg-5 px-md-5 px-1">
            <div
              className={
                (lang() === "en" ? "text-lg-start" : "text-lg-end") +
                " detail_box text-center"
              }
            >
              <h1 data-aos="fade-up">{getData("title")}</h1>
              <p data-aos="fade-right">{getData("subtitle")}</p>
              <div data-aos="fade-up" className="btn-box">
                <a
                  type="button"
                  href="#home"
                  data-bs-toggle="modal"
                  data-bs-target="#mWhatsapp"
                  className="btn-1"
                >
                  {getTrans("Booking")}
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 px-xl-5 px-lg-5 px-md-2 px-1 crs d-none d-md-block">
            <img data-aos="fade-left" alt="..." src={right} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
