import { createContext, useState } from "react";
import translations from "./entreprise/lang.json";
const TranslationContext = createContext();
const TranslationProvider = ({ children }) => {
  const [trans, setTrans] = useState(
    translations[
      localStorage.getItem("lang") === null ||
      (localStorage.getItem("lang") !== "en" &&
        localStorage.getItem("lang") !== "ar")
        ? "ar"
        : localStorage.getItem("lang")
    ]
  );
  const lang = () => {
    return localStorage.getItem("lang") === null ||
      (localStorage.getItem("lang") !== "en" &&
        localStorage.getItem("lang") !== "ar")
      ? "ar"
      : localStorage.getItem("lang");
  };
  const updateLang = (l) => {
    localStorage.setItem("lang", l);
    setTrans(translations[l]);
  };
  const getTrans = (key) => {
    return trans[key] ?? key;
  };
  return (
    <TranslationContext.Provider value={{ updateLang, getTrans, lang }}>
      {children}
    </TranslationContext.Provider>
  );
};
export { TranslationContext, TranslationProvider };
