import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./App";
import { TranslationProvider } from "./TranslationContext";
import { DataProvider } from "./DataContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <TranslationProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </TranslationProvider>
  </React.StrictMode>
);
