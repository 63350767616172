import "./Anas.css";
import logo from "../../entreprise/logo.png";
import { useEffect, useState } from "react";
function Anas() {
  const [isMobile, setIsMobile] = useState(false);
  const [lang, setLang] = useState("");

  const checkIsMobile = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setIsMobile(width < 991);
  };
  const ilang = () => {
    return localStorage.getItem("ilang") === null
      ? "ar"
      : localStorage.getItem("ilang");
  };
  const updateLang = (l) => {
    localStorage.setItem("ilang", l);
    setLang(l);
  };

  useEffect(() => {
    setLang(ilang);
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <>
      <div className="content">
        <div className="row justify-content-around w-100 m-auto">
          <div className="col-xl-4 col-lg-6 col-md-12 px-xl-5 px-lg-5 px-md-5 px-1 text-center">
            <h1 className={lang === "en" ? "txtec-en" : "txtec-ar"}>
              {lang === "en" ? (
                <>
                  <span>Drive in </span>
                  <span>Style</span>
                </>
              ) : (
                <>
                  <span>القيادة </span>
                  <span>بأناقة</span>
                </>
              )}
            </h1>
            <h1
              className={
                (lang === "en" ? "txtw-en" : "txtw-ar") +
                " text-white mt-5 mb-5"
              }
            >
              {lang === "en" ? "With" : "مـع"}
            </h1>
            <img src={logo} alt="logo" />
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 px-xl-5 px-lg-5 px-md-2 px-1 align-self-center">
            {!isMobile && (
              <div className="container-fluid text-center">
                <div className="row align-items-center">
                  <div className="col">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#modalPosition"
                      className="rounded-pill position-relative btn1 location"
                    >
                      <i className="bi bi-geo-alt fs-2 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                      <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                        {lang === "en" ? "localization" : "الموقع"}
                      </span>
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="rounded-pill position-relative btn1 instagram"
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/anas.car.rental/"
                        );
                      }}
                    >
                      <i className="bi bi-instagram fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                      <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                        {lang === "en" ? "Instagram" : "انستقرام"}
                      </span>
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="rounded-pill position-relative btn1 email"
                      onClick={() => {
                        window.open("mailto:Sayib.cl@hotmail.com");
                      }}
                    >
                      <i className="bi bi-envelope-at fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                      <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                        {lang === "en" ? "Email" : "البريد الإلكتروني"}
                      </span>
                    </button>
                  </div>
                  <div className="col">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#modalWhatsapp"
                      className="rounded-pill position-relative btn1 whatsapp"
                    >
                      <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                      <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                        {lang === "en" ? "Whatsapp" : "واتس اب"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="container-fluid text-center mt-4">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#modalPosition"
                    className="rounded-pill position-relative btn2 location"
                  >
                    <i className="bi bi-geo-alt fs-2 position-absolute top-50 start-50 translate-middle"></i>
                  </button>
                  <button
                    className="rounded-pill position-relative btn2 instagram"
                    onClick={() => {
                      window.open("https://www.instagram.com/anas.car.rental/");
                    }}
                  >
                    <i className="bi bi-instagram fs-3 position-absolute top-50 start-50 translate-middle"></i>
                  </button>
                  <button
                    className="rounded-pill position-relative btn2 email"
                    onClick={() => {
                      window.open("mailto:Sayib.cl@hotmail.com");
                    }}
                  >
                    <i className="bi bi-envelope-at fs-3 position-absolute top-50 start-50 translate-middle"></i>
                  </button>

                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#modalWhatsapp"
                    className="rounded-pill position-relative btn2 whatsapp"
                  >
                    <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-50 translate-middle"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-white d-flex position-absolute bottom-0 start-50 translate-middle-x mb-4">
          <span
            onClick={() => updateLang("ar")}
            role={lang === "en" ? "button" : ""}
            className={lang === "en" ? "text-info" : "text-white ar"}
          >
            {lang === "en" ? "Arabe" : "العربية"}
          </span>
          <div className="divider mx-2"></div>
          <span
            onClick={() => updateLang("en")}
            role={lang === "en" ? "" : "button"}
            className={lang === "en" ? "text-white" : "text-info ar"}
          >
            {lang === "en" ? "English" : "الإنجليزية"}
          </span>
        </div>
      </div>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div
        className="modal fade"
        id="modalWhatsapp"
        tabIndex="-1"
        aria-labelledby="modalWhatsappLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <button
                className="rounded-pill position-relative btn3 whatsapp"
                onClick={() => {
                  window.open(
                    isMobile
                      ? "whatsapp://send?phone=966505153121"
                      : "https://api.whatsapp.com/send/?phone=966505153121"
                  );
                }}
              >
                <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                  {lang === "en"
                    ? "King Khalid Rd, An Naziyah"
                    : "طريق الملك خالد، النازية"}
                </span>
                <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
              </button>
              <button
                className="rounded-pill position-relative btn3 whatsapp"
                onClick={() => {
                  window.open(
                    isMobile
                      ? "whatsapp://send?phone=966546799208"
                      : "https://api.whatsapp.com/send/?phone=966546799208"
                  );
                }}
              >
                <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                  {lang === "en"
                    ? "King Fahd Rd, Al Muntazah"
                    : "طريق الملك فهد، حي المنتزة"}
                </span>
                <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalPosition"
        tabIndex="-1"
        aria-labelledby="modalPositionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <button
                className="rounded-pill position-relative btn3 location"
                onClick={() => {
                  window.open("https://goo.gl/maps/4b2tfyMZ2qWxwTZ7A");
                }}
              >
                <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                  {lang === "en"
                    ? "King Khalid Rd, An Naziyah"
                    : "طريق الملك خالد، النازية"}
                </span>
                <i className="bi bi-geo-alt fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
              </button>
              <button
                className="rounded-pill position-relative btn3 location"
                onClick={() => {
                  window.open("https://goo.gl/maps/PGrG7zLA1a7shNPU7");
                }}
              >
                <span className={"fs-5 " + (lang === "en" ? "" : "ar")}>
                  {lang === "en"
                    ? "King Fahd Rd, Al Muntazah"
                    : "طريق الملك فهد، حي المنتزة"}
                </span>
                <i className="bi bi-geo-alt fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Anas;
