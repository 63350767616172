import { useContext } from "react";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
import "./Car.css";
import Card from "./Card";
function Cars() {
  const { fleet } = useContext(DataContext);
  const { lang } = useContext(TranslationContext);

  return (
    <div
      dir={lang() === "en" ? "ltr" : "rtl"}
      className={(lang() === "en" ? "" : "ar ") + "cars"}
    >
      <div className="container text-center cars_section scroll-y ">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
          {fleet.map((f, i) => (
            <div key={i} className="col">
              <Card f={f} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cars;
