import { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { TranslationContext } from "../../TranslationContext";
import "./Navbar.css";
import logo from "../../entreprise/logo.png";
import { DataContext } from "../../DataContext";
function Navbar() {
  const { getTrans, updateLang, lang } = useContext(TranslationContext);
  const { sectionRefs, getData } = useContext(DataContext);
  const [activeSection, setActiveSection] = useState("home");
  const [isShow, setIsShow] = useState(false);
  const navbarCollapseRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const checkIsMobile = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setIsMobile(width < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  const togglerLang = (e) => {
    const l = e.target.value;
    if (l === "en") {
      updateLang("en");
    } else {
      updateLang("ar");
    }
  };
  const handleNavClick = (section) => {
    setActiveSection(section);
  };
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };
  const togglerClick = () => {
    if (
      navbarCollapseRef.current &&
      navbarCollapseRef.current.classList.contains("collapsed")
    ) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  };
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    sectionRefs.current.forEach((sectionRef) => {
      observer.observe(sectionRef);
    });
    return () => {
      observer.disconnect();
    };
  });
  useEffect(() => {
    if (
      navbarCollapseRef.current &&
      navbarCollapseRef.current.classList.contains("collapsed")
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsNavbarActive(true);
      } else {
        setIsNavbarActive(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      dir={lang() === "en" ? "ltr" : "rtl"}
      className={(lang() === "en" ? "" : "ar ") + "header_section"}
    >
      <nav
        className={
          "navbar navbar-expand-lg custom_nav-container w-100 " +
          ((isNavbarActive || isShow) && "active")
        }
      >
        <Link
          className="navbar-brand"
          style={
            lang() === "en" ? { marginLeft: "20px" } : { marginRight: "20px" }
          }
          to="/"
        >
          <img height={55} src={logo} alt={getData("name")} />
        </Link>
        <button
          className={"navbar-toggler " + (lang() === "en" ? "me-3" : "ms-3")}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={togglerClick}
          ref={navbarCollapseRef}
        >
          <span className="s-1"> </span>
          <span className="s-2"> </span>
          <span className="s-3"> </span>
        </button>

        <div
          className="test navbar-collapse collapse"
          id="navbarSupportedContent"
        >
          <div className="d-flex mx-auto flex-column flex-lg-row align-items-center">
            <ul className="navbar-nav  ">
              <li
                className={"nav-item " + (activeSection === "home" && "active")}
              >
                <a
                  className="nav-link"
                  href="/#home"
                  onClick={() => handleNavClick("home")}
                >
                  {getTrans("Home")}
                </a>
              </li>
              <li
                className={
                  "nav-item " + (activeSection === "about" && "active")
                }
              >
                <a
                  className="nav-link"
                  href="/#about"
                  onClick={() => handleNavClick("about")}
                >
                  {getTrans("About")}
                </a>
              </li>
              <li
                className={
                  "nav-item " + (activeSection === "our_benefits" && "active")
                }
              >
                <a
                  className="nav-link"
                  href="/#our_benefits"
                  onClick={() => handleNavClick("our_benefits")}
                >
                  {getTrans("Our benefits")}
                </a>
              </li>
              <li
                className={
                  "nav-item " +
                  (activeSection === "documents&conditions" && "active")
                }
              >
                <a
                  className="nav-link"
                  href="/#documents&conditions"
                  onClick={() => handleNavClick("documents&conditions")}
                >
                  {getTrans("Documents & Conditions")}
                </a>
              </li>
              <li
                className={"nav-item " + (activeSection === "cars" && "active")}
              >
                <a
                  className="nav-link"
                  href="/#cars"
                  onClick={() => handleNavClick("cars")}
                >
                  {getTrans("Vehicles")}
                </a>
              </li>
              <li
                className={
                  "nav-item " + (activeSection === "contact_us" && "active")
                }
              >
                <a
                  className="nav-link"
                  href="/#contact_us"
                  onClick={() => handleNavClick("contact_us")}
                >
                  {getTrans("Contact us")}
                </a>
              </li>
            </ul>
            <select
              style={{
                background: "transparent",
                color: "#2d9ca2",
                border: 0,
                cursor: "pointer",
              }}
              onChange={togglerLang}
              className={
                (lang() === "ar" && !isMobile ? "start-0 ms-3" : "end-0 me-3") +
                " position-absolute top-50 translate-middle-y fw-bold pointer"
              }
              aria-label=".form-select-sm example"
            >
              <option
                className="text-dark fw-lighter"
                value="en"
                selected={lang() === "en"}
              >
                English
              </option>
              <option
                className="text-dark fw-lighter"
                value="ar"
                selected={lang() === "ar"}
              >
                {lang() === "ar" ? "العربية" : "Arabe"}
              </option>
            </select>
          </div>
        </div>
      </nav>
      <Outlet />
    </header>
  );
}

export default Navbar;
