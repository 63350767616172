import "./Work.css";
import doc from "../../entreprise/doc.svg";
import { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
function Work() {
  const { getTrans, lang } = useContext(TranslationContext);
  const { getData, addSectionRef } = useContext(DataContext);
  const docs = getData("docs");
  const conditions = getData("conditions");
  return (
    <section
      dir={lang() === "en" ? "ltr" : "rtl"}
      ref={addSectionRef}
      id="documents&conditions"
      className={(lang() === "en" ? "" : "ar ") + "work_section layout_padding"}
    >
      <div className="container">
        <div className="heading_container">
          <h2
            id="wtitle"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            {getTrans("Work")}
            <i className="ms-2 bi bi-circle-fill fs-4"></i>
          </h2>
          <p
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {getTrans("Work s")}
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="detail_container">
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                className="box b-1"
              >
                <div className="top-box">
                  <div className="icon-box">
                    <i className="bi bi-file-earmark-text-fill fs-1"></i>
                  </div>
                  <h5>{getTrans("Documents")}</h5>
                </div>
                <div className="bottom-box">
                  <ul>
                    {docs.map((d, i) => (
                      <li key={i}>{d}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                className="box b-1"
              >
                <div className="top-box">
                  <div className="icon-box">
                    <i className="bi bi-file-earmark-check-fill fs-1"></i>
                  </div>
                  <h5>{getTrans("Conditions")}</h5>
                </div>
                <div className="bottom-box">
                  <ul>
                    {conditions.map((d, i) => (
                      <li key={i}>{d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="img-box">
              <img
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
                src={doc}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
