import "./Info.css";
import logo from "../../entreprise/logo.png";
import { useContext } from "react";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
function Info() {
  const { getConf, addSectionRef, getData, isMobile } = useContext(DataContext);
  const { getTrans, lang } = useContext(TranslationContext);
  const about = getData("about");
  return (
    <>
      <div className="row align-items-start">
        <div className="col-lg-6 col-12 ">
          <div
            className={
              "card shadow-sm mb-3 mx-2 " +
              (lang() === "en" ? "ms-lg-3" : "me-lg-3")
            }
            style={{ border: "#2d9ca2 1px solid" }}
          >
            <div
              className="card-header border-0 shadow-sm text-white text-center"
              style={{ backgroundColor: "#2d9ca2" }}
            >
              {lang() === "en"
                ? "King Khalid Rd, An Naziyah, Buraydah 52366"
                : "طريق الملك خالد، النازية، بريدة 52366"}
            </div>
            <div className="card-body p-0">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3575.354908769258!2d43.97320997608765!3d26.34738048415557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f5937f0e1a0a9%3A0x94483a9b2c75e048!2z2KPZhtizINmE2KrYo9is2YrYsSDYp9mE2LPZitin2LHYp9iq!5e0!3m2!1sen!2sma!4v1684097504479!5m2!1sen!2sma"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 ">
          <div
            className={
              "card shadow-sm mb-3 mx-2 " +
              (lang() === "en" ? "me-lg-3" : "ms-lg-3")
            }
            style={{ border: "#2d9ca2 1px solid" }}
          >
            <div
              className="card-header border-0 shadow-sm text-white text-center"
              style={{ backgroundColor: "#2d9ca2" }}
            >
              {lang() === "en"
                ? "King Fahd Rd, Al Muntazah, Buraydah 52381"
                : "طريق الملك فهد، حي المنتزة، بريدة 52381"}
            </div>
            <div className="card-body p-0">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3575.403332603725!2d43.946532276087815!3d26.345813584221688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x157f5987e73796d1%3A0x3b0896fcd4299a42!2z2KPZhtizINmE2KrYo9is2YrYsSDYp9mE2LPZitin2LHYp9iq!5e0!3m2!1sen!2sma!4v1684097752023!5m2!1sen!2sma"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bg">
        <div
          dir={lang() === "en" ? "ltr" : "rtl"}
          className={(lang() === "en" ? "" : "ar ") + "infos"}
        >
          <section
            ref={addSectionRef}
            id="contact_us"
            className="info_section layout_padding2"
          >
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-5">
                  <div className="info_logo">
                    <img width={150} alt={getData("name")} src={logo} />
                    <p className="mt-3">
                      {about.slice(0, about.indexOf(".") + 1)}
                    </p>
                  </div>
                </div>
                <div className="col-md-5 mt-5 mt-md-0">
                  <div className="info_contact">
                    <h4 className="fw-bold text-nowrap">
                      {getTrans("Contact us")}
                    </h4>
                    <a href="#contact_us">
                      <div className="img-box">
                        <i className="bi bi-geo-alt-fill fs-4 text-white"></i>
                      </div>
                      <p dir="ltr">
                        {lang() === "en"
                          ? "King Khalid Rd, An Naziyah, Buraydah 52366"
                          : "طريق الملك خالد، النازية، بريدة 52366"}
                      </p>
                    </a>
                    <a
                      href={
                        isMobile
                          ? "whatsapp://send?phone=966505153121"
                          : "https://api.whatsapp.com/send/?phone=966505153121"
                      }
                    >
                      <div className="img-box">
                        <i className="bi bi-telephone-fill fs-4 text-white"></i>
                      </div>
                      <p dir="ltr">+966 505 153 121</p>
                    </a>
                    <a href={"mailto:" + getConf("email")}>
                      <div className="img-box">
                        <i className="bi bi-envelope-at-fill fs-4 text-white"></i>
                      </div>
                      <p>{getConf("email")}</p>
                    </a>
                    <br />
                    <a href="#contact_us">
                      <div className="img-box">
                        <i className="bi bi-geo-alt-fill fs-4 text-white"></i>
                      </div>
                      <p dir="ltr">
                        {lang() === "en"
                          ? "King Fahd Rd, Al Muntazah, Buraydah 52381"
                          : "طريق الملك فهد، حي المنتزة، بريدة 52381"}
                      </p>
                    </a>
                    <a
                      href={
                        isMobile
                          ? "whatsapp://send?phone=966546799208"
                          : "https://api.whatsapp.com/send/?phone=966546799208"
                      }
                      target="_blank"
                    >
                      <div className="img-box">
                        <i className="bi bi-telephone-fill fs-4 text-white"></i>
                      </div>
                      <p dir="ltr">+966 546 799 208</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid footer_section">
            <div className="container">
              <p>
                &copy; <span id="displayYear"></span> 2023
                <a href="#contact_us"> {getData("name")}</a>.
                {getTrans("All Rights Reserved")}.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Info;
