import { useContext } from "react";
//import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";

function Card({ f }) {
  //const { getConf } = useContext(DataContext);
  const { getTrans, lang } = useContext(TranslationContext);
  return (
    <div
      className={
        (lang() === "en" ? "" : "ar ") +
        "card  border-0 shadow bg-body-tertiary rounded h-100"
      }
    >
      <div className="h-100 position-relative" data-aos="zoom-in">
        <img src={f.image} className="card-img-top" alt="..." />
        {/* <span className="position-absolute bottom-0 end-0 badge rounded-pill bg-success fs-5 me-2">
          {f.price + " " + getConf("currency")}
  </span>*/}
      </div>
      <div className="card-body" data-aos="zoom-in">
        <h5 className="card-title fw-bold mb-3">{`${f.make} ${f.model}`}</h5>
        <div className="row g-3">
          <div className="col">
            <i className="fs-2 bi bi-fuel-pump-fill"></i>
            <br />
            {getTrans(f.fuel_type)}
          </div>
          <div className="col">
            <i className="fs-2 bi bi-people-fill"></i>
            <br />
            {f.n_seats}
          </div>
          <div className="col">
            <i className="fs-2 bi bi-pin-map-fill"></i>
            <br />
            {getTrans(f.transmission)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
