import { useContext, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
import "./Car.css";
import Card from "./Card";
function Car() {
  const { fleet, addSectionRef, isMobile } = useContext(DataContext);
  const { getTrans, lang } = useContext(TranslationContext);
  return (
    <section
      dir={lang() === "en" ? "ltr" : "rtl"}
      ref={addSectionRef}
      id="cars"
      className={
        (lang() === "en" ? "" : "ar ") + "car_section layout_padding-bottom"
      }
    >
      <div className="container">
        <div className="heading_container mb-3">
          <h2 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            {getTrans("Vehicles")}{" "}
            <i className="ms-2 bi bi-circle-fill fs-4"></i>
          </h2>
          <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            {getTrans("Our fleet s")}
          </p>
        </div>
      </div>
      <div className="container text-center mt-4">
        {!isMobile && (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
            {fleet.slice(0, 8).map((f, i) => (
              <div key={i} className="col">
                <Card f={f} />
              </div>
            ))}
          </div>
        )}
        {isMobile && (
          <Carousel indicators={false}>
            {fleet.slice(0, 6).map((f, i) => (
              <Carousel.Item key={i}>
                <Card f={f} />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        {((isMobile && fleet.length > 6) ||
          (!isMobile && fleet.length > 8)) && (
          <a className="mt-5" data-aos="fade-up" id="more" href="/cars">
            {getTrans("Read more")}
          </a>
        )}
      </div>
    </section>
  );
}

export default Car;
