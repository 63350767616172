import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Car from "./components/car/Car";
import Home from "./components/home/Home";
import Info from "./components/info/Info";
import Work from "./components/work/Work";
import Benefit from "./components/benefit/Benefit";
import Cars from "./components/car/Cars";
import { useContext, useEffect } from "react";
import AboutUs from "./components/about/AboutUs";
import Anas from "./components/anas/Anas";
import { TranslationContext } from "./TranslationContext";
import { DataContext } from "./DataContext";

function App() {
  const { isMobile } = useContext(DataContext);
  const { lang } = useContext(TranslationContext);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route
              path="/"
              element={
                <>
                  <div className="hero_area">
                    <Home />
                  </div>
                  <About />
                  <Benefit />
                  <Work />
                  <Car />
                  <Info />
                  <div
                    className="modal fade"
                    id="mWhatsapp"
                    tabIndex="-1"
                    aria-labelledby="modalWLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <button
                            onClick={() => {
                              window.open(
                                isMobile
                                  ? "whatsapp://send?phone=966505153121"
                                  : "https://api.whatsapp.com/send/?phone=966505153121"
                              );
                            }}
                            className="rounded-pill position-relative btn3 whatsapp"
                          >
                            <span
                              className={
                                "fs-5 " + (lang() === "en" ? "" : "ar")
                              }
                            >
                              {lang() === "en"
                                ? "King Khalid Rd, An Naziyah"
                                : "طريق الملك خالد، النازية"}
                            </span>
                            <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                          </button>
                          <button
                            className="rounded-pill position-relative btn3 whatsapp"
                            onClick={() => {
                              window.open(
                                isMobile
                                  ? "whatsapp://send?phone=966546799208"
                                  : "https://api.whatsapp.com/send/?phone=966546799208"
                              );
                            }}
                          >
                            <span
                              className={
                                "fs-5 " + (lang() === "en" ? "" : "ar")
                              }
                            >
                              {lang() === "en"
                                ? "King Fahd Rd, Al Muntazah"
                                : "طريق الملك فهد، حي المنتزة"}
                            </span>
                            <i className="bi bi-whatsapp fs-3 position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <Route path="/cars" element={<Cars />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Route>
          <Route path="/arc" element={<Anas />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
