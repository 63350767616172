import { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
import "./Benefit.css";

function Benefit() {
  const { getTrans, lang } = useContext(TranslationContext);
  const { getData, addSectionRef } = useContext(DataContext);
  const benefits = getData("benefits");
  return (
    <section
      dir={lang() === "en" ? "ltr" : "rtl"}
      ref={addSectionRef}
      id="our_benefits"
      className={
        (lang() === "en" ? "" : "ar ") + "benefit_section layout_padding"
      }
    >
      <div className="container-fluid">
        <div className="heading_container">
          <h2 data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            {getTrans("Our benefits")}
            <i className="ms-2 bi bi-circle-fill fs-4"></i>
          </h2>
          <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            {getTrans("Our benefits s")}
          </p>
        </div>

        <div className="benefit_container">
          {benefits.map((b, i) => (
            <div key={i} className="box">
              <div className="img-box">
                <img data-aos="zoom-out" src={b.icon} alt="" />
              </div>
              <div className="detail-box">
                <h5 data-aos="zoom-in">{b.title}</h5>
                <p data-aos="zoom-in">{b.sub}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Benefit;
